import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React, { FC } from "react"
import Layout from "../components/layout"

export const query = graphql`
  query {
    mainImage: file(name: { eq: "404" }) {
      childImageSharp {
        id
        fluid(maxWidth: 1152, maxHeight: 420, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    senestemenu: allSanityMenu(
      sort: { fields: [aar, uge], order: [DESC, DESC] }
      limit: 1
    ) {
      nodes {
        uge
        aar
      }
    }
  }
`

const NotFoundPage: FC<any> = (props) => {
  const aar = props.data.senestemenu.nodes[0].aar
  const uge = props.data.senestemenu.nodes[0].uge

  // Brugeren prøver at tilgå en menu der ikke er oprettet endnu
  if (props.location.pathname.includes("/bestil/uge")) {
    return <h1>Ingen menu endnu</h1>
  } else {
    return (
      <Layout title="404: Not found" aar={aar} uge={uge}>
        <pre>{JSON.stringify(props, null, 2)}</pre>
        <Img fluid={props.data.mainImage.childImageSharp.fluid} />
        <h1>
          Hov, denne side eksisterer ikke endnu{" "}
          <span role="img" aria-label="Thinking emoji">
            🤔
          </span>
        </h1>
        <p>
          Har du prøvet at bestille dagens ret er vi måske ikke helt klar med
          menu'en endnu
        </p>
        <p>Prøv venligst igen om et par timer, på forhånd tak</p>
        <br />
        <p>
          Mener du at siden her burde være her, så{" "}
          <Link className="underline" to="/kontakt/">
            kontakt os gerne
          </Link>
        </p>
      </Layout>
    )
  }
}

export default NotFoundPage
